<template>
   <div>
	  <section id="expences-list">
		 <b-row>
			<b-col lg="3">
			   <b-card-actions action-collapse
							   title="Φίλτρα">
				  <!-- Filters -->
				  <b-form-group label="Από"
								label-for="start-date">
					 <flat-pickr
						 id="start-date"
						 v-model="form.from"
						 :config="{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                }"
						 :value="null"
						 class="form-control"
					 >
					 </flat-pickr>
				  </b-form-group>
				  <b-form-group label="Έως"
								label-for="end-date">
					 <flat-pickr
						 id="end-date"
						 v-model="form.to"
						 :config="{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                }"
						 :value="null"
						 class="form-control"
					 >
					 </flat-pickr>
				  </b-form-group>

				  <b-form-group label="Κατηγορία">
					 <v-select
						 v-model="form.categoryId"
						 :options="getSettingExpense"
						 :reduce="val => val.id"
						 input-id="project-category"
						 label="title"
						 placehoder="Επιλέξτε..."
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 </v-select>
				  </b-form-group>

				  <b-button block
							class="mt-2"
							variant="outline-primary"
							@click="generateReport">
					 <span>{{ $t("generic.view-results") }}</span>
				  </b-button>
				  <b-button block
							variant="flat-warning"
							@click="reset">
					 Καθαρισμός
				  </b-button>
			   </b-card-actions>
			   <!-- END: Filters -->
			</b-col>

			<b-col lg="9">
			   <!--Πίνακας αποτελεσμάτων -->
			   <b-card no-body>
				  <b-row v-if="showPdf"
						 style="height: 100vh">


					 <vue-pdf-app :pdf="$store.getters.getExpensesList"
								  class="w-100 h-100" />


				  </b-row>
			   </b-card>
			   <!--Πίνακας αποτελεσμάτων -->
			</b-col>
		 </b-row>
	  </section>
   </div>
</template>

<script>
import {
   BButton,
   BButtonGroup,
   BButtonToolbar,
   BCard,
   BCardText,
   BCol,
   BFormGroup,
   BFormRadio,
   BFormRadioGroup,
   BRow,
   BTable,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import FormMixin from "@/Mixins/FormMixin";
import {mapGetters} from "vuex";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardText,
	  BFormGroup,
	  vSelect,
	  BButton,
	  BButtonToolbar,
	  BButtonGroup,
	  BCardActions,
	  BTable,
	  flatPickr,
	  BFormRadio,
	  BFormRadioGroup,
   },
   mixins: [FormMixin],
   computed: {
	  ...mapGetters(['getSettingExpense']),


   },
   data() {
	  return {
		 BalanceOptions: [
			{text: "Όλα", value: "all"},
			{text: "Εξοφλημένα", value: "paid"},
			{text: "Με οφειλή", value: "pending"},
		 ],
		 showPdf: false,
		 form: {
			balance: 'all',
			from: null,
			to: null,
			categoryId: null,
		 },
	  };
   },
   methods: {
	  reset(e) {
		 e.preventDefault();
		 this.resetField(this.form);
	  },
	  async generateReport() {
		 await this.$store.dispatch('fetchPdfExpensesList', this.removeEmpty(this.form));


		 this.showPdf = true;
	  }
   },
   async created() {
	  caldoSettings.removeBreadcrumb();
	  await this.$store.dispatch('fetchExpenseSetting');
   }
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>